import { message, Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import blacklogo from "./assests/blacklogo.png";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const Login = (props) => {
  console.log(process.env.REACT_APP_API_URL);
  const initialstate = {
    email: "",
    password: "",
  };
  const [loading, setLoading] = useState(false); // Tracks the loading state

  const [showPassword, setShowPassword] = useState(false); // Password visibility toggle state
  const [FormData, setFormData] = useState(initialstate);
  const { email, password } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };

  const validation = () => {
    if (email !== "") {
      if (email.includes("@") && email.includes(".")) {
        if (password !== "") {
          LoginApi();
        } else if (password === "") {
          setFormData({
            ...FormData,
            error: "Password is Required",
          });
          message.error("Password is Required", [4]);
        }
      } else {
        setFormData({
          ...FormData,
          error: "Email is not valid",
        });
        message.error("Email is not valid", [4]);
      }
    } else if (email === "") {
      setFormData({
        ...FormData,
        error: "Email is Required",
      });
      message.error("Email is Required", [4]);
    }
  };

  const LoginApi = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/admin/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          localStorage.setItem("Login", true);
          setFormData({
            login: true,
          });
        } else {
          message.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };
  const check = localStorage.getItem("Login");
  return check === "true" ? (
    <Redirect to="/dashboard" />
  ) : (
    <>
      <div className="col-11 mx-auto pt-3 ">
        <div className=" mx-auto row   marginTop">
          <div className="col-md-6 col-11 mx-auto mt-2 bg-white p-5 shadow Radius_4">
            <div className="col-md-6 col-10 mx-auto">
              <img src={blacklogo} alt="" className=" mx-auto"></img>
            </div>

            <form className="pt-4">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Enter email"
                  name="email"
                  value={email}
                  onChange={onHandleChange}
                ></input>
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter new password"
                  name="password"
                  value={password}
                  onChange={onHandleChange}
                  disabled={loading}
                  style={{ paddingRight: "40px" }} // Ensure space for the icon
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)", // Vertically center the icon
                  }}
                >
                  {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </span>
              </div>

              <div className="form-group">
                <NavLink to="/forget" className="Black">
                  Forget Password?
                </NavLink>
              </div>
              <button
                type="button"
                className="btn skyblue White col-12"
                onClick={validation}
                disabled={loading}
              >
                {loading ? <Spin /> : "Login"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
