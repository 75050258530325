import React, { useEffect, useState } from "react";
import TransactionComponent from "./TransactionComponent";
import { message } from "antd";
import axios from "axios";
const Transactions = () => {
  const initialstate = {
    TransactionData: [],
  };
  const [FormData, setFormData] = useState(initialstate);
  const { TransactionData } = FormData;

  useEffect(() => {
    const getTransactions = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/admin/all_payments`)
        .then((response) => {
          if (response.data.success) {
            setFormData({
              TransactionData: response.data.data,
            });
          }
        })
        .catch((error) => {
          message?.error(error);
        });
    };
    getTransactions();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row mr-0">
        <div className="col-11 mx-auto pt-3">
          <h6>Transactions</h6>
          {TransactionData.length > 0 && (
            <TransactionComponent data={TransactionData} />
          )}
        </div>
      </div>
    </>
  );
};

export default Transactions;
