import React, { useEffect, useState } from "react";
import HiringDataTable from "./HiringDataTable";
import { message } from "antd";
import axios from "axios";

const Hiring = () => {
  const initialstate = {
    HiringData: [],
  };
  const [FormData, setFormData] = useState(initialstate);
  const { HiringData } = FormData;

  useEffect(() => {
    FetchHiring();
    // eslint-disable-next-line
  }, []);

  const FetchHiring = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/all_hirings`)
      .then((response) => {
        if (response.data.success) {
          setFormData({
            ...FormData,
            HiringData: response.data.data,
          });
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  return (
    <>
      <div className="row mr-0">
        <div className="col-11 mx-auto pt-3">
          <h6>Contact Forms</h6>
          <HiringDataTable data={HiringData} FetchHiring={FetchHiring} />
        </div>
      </div>
    </>
  );
};

export default Hiring;
