import React from "react";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import blacklogo from "../assests/blacklogo.png";
import Dashboard from "./Dashboard";
import Orders from "./Orders/Orders";
import BusinessListing from "./BusinessListing/BusinessListing";
import Blogs from "./Blogs/Blogs";
import Transactions from "./Transactions/Transactions";
import Users from "./Users/Users";
import FeaturedListing from "./FeaturedListing/FeaturedListing";
import ContactForms from "./ContactForms/ContactForms";
import Hiring from "./Hiring/Hiring";
import BusinessRequests from "./BusinessListing/BusinessRequests";
import Profile from "./Profile/Profile";
import appConfig from "../assests/appConfig.svg";
import order from "../assests/order.svg";
import business from "../assests/business.svg";
import blog from "../assests/blog.svg";
import transactions from "../assests/transaction.svg";
import user from "../assests/user.svg";
import contact from "../assests/contact.svg";
import featured from "../assests/featured.svg";
import hired from "../assests/hired.svg";
import $ from "jquery";
import UserRequests from "./Users/UserRequests";
import SendPayemnt from "./SendPayment";

const { Header, Sider } = Layout;
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  handleClick(e) {}
  onLogout = () => {
    localStorage.clear();
    this.setState({
      logout: true,
    });
  };
  render(props) {
    if ($(window).width() < 500) {
      // eslint-disable-next-line
      if (this.state.collapsed == false) {
        this.setState({
          collapsed: true,
        });
      }
    } else if ($(window).width() > 500) {
    }
    return (
      // eslint-disable-next-line
      this.state.logout == false ? (
        <>
          <Layout className="h-100 ">
            <Sider
              trigger={null}
              collapsible
              collapsed={this.state.collapsed}
              width={250}
            >
              {/* <Sider trigger={null} collapsible collapsed={this.state.collapsed}> */}

              <Menu
                mode="inline"
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["dashboard"]}
                style={{ height: "100%" }}
              >
                <div className="profile">
                  <img src={blacklogo} alt="" />
                  {!this.state.collapsed && (
                    <p
                      className="text-center mb-0 font23 padding30px"
                      style={{ fontWeight: "700" }}
                    >
                      Admin{" "}
                    </p>
                  )}
                </div>

                {/* <SubMenu key="home" title="Home" icon={<img src={home} alt="" className="icons options"></img>} > */}
                <NavLink
                  to="/dashboard"
                  className="ant-menu-item ant-menu-item-only-child "
                >
                  {!this.state.collapsed && (
                    <Menu.Item key="dashboard">
                      <span className="qwe">Dashboard</span>
                    </Menu.Item>
                  )}
                </NavLink>
                {/* </SubMenu> */}

                <SubMenu
                  key="sub1"
                  title="App Management"
                  icon={
                    <img src={appConfig} alt="" className="icons options"></img>
                  }
                >
                  <SubMenu
                    key="Business"
                    title="Business Listing"
                    icon={<img src={business} alt="" className="icons"></img>}
                  >
                    <NavLink
                      to="/businesslisting"
                      className="ant-menu-item ant-menu-item-only-child "
                    >
                      <Menu.Item key="2">Business Listing</Menu.Item>
                    </NavLink>

                    <NavLink
                      to="/businessrequests"
                      className="ant-menu-item ant-menu-item-only-child "
                    >
                      <Menu.Item key="2">Business Requests</Menu.Item>
                    </NavLink>
                  </SubMenu>

                  <SubMenu
                    key="Users"
                    title="Users"
                    icon={<img src={user} alt="" className="icons"></img>}
                  >
                    <NavLink
                      to="/users"
                      className="ant-menu-item ant-menu-item-only-child "
                    >
                      <Menu.Item key="7">Users</Menu.Item>
                    </NavLink>

                    <NavLink
                      to="/userrequests"
                      className="ant-menu-item ant-menu-item-only-child "
                    >
                      <Menu.Item key="222">User Requests</Menu.Item>
                    </NavLink>
                  </SubMenu>

                  <NavLink
                    to="/blogs"
                    className="ant-menu-item ant-menu-item-only-child "
                  >
                    <Menu.Item key="3">
                      <img src={blog} alt="" className="icons"></img>Blogs
                    </Menu.Item>
                  </NavLink>

                  <NavLink
                    to="/orders"
                    className="ant-menu-item ant-menu-item-only-child "
                  >
                    <Menu.Item key="1">
                      <img src={order} alt="" className="icons"></img>Orders
                    </Menu.Item>
                  </NavLink>

                  <NavLink
                    to="/transactions"
                    className="ant-menu-item ant-menu-item-only-child "
                  >
                    <Menu.Item key="4">
                      <img src={transactions} alt="" className="icons"></img>{" "}
                      Transactions
                    </Menu.Item>
                  </NavLink>

                  <NavLink
                    to="/Contactus"
                    className="ant-menu-item ant-menu-item-only-child "
                  >
                    <Menu.Item key="9">
                      <img src={contact} alt="" className="icons"></img> Contact
                      Forms
                    </Menu.Item>
                  </NavLink>

                  <NavLink
                    to="/featuredlisting"
                    className="ant-menu-item ant-menu-item-only-child "
                  >
                    <Menu.Item key="10">
                      <img src={featured} alt="" className="icons"></img>{" "}
                      Featured Listing
                    </Menu.Item>
                  </NavLink>

                  <NavLink
                    to="/Hirings"
                    className="ant-menu-item ant-menu-item-only-child "
                  >
                    <Menu.Item key="11">
                      <img src={hired} alt="" className="icons"></img> Hiring
                    </Menu.Item>
                  </NavLink>
                </SubMenu>
              </Menu>
            </Sider>
            <Layout className="site-layout">
              <Header className="site-layout-background">
                <div className="row mx-auto">
                  <div className="mr-auto Trigger">
                    {React.createElement(
                      this.state.collapsed
                        ? MenuUnfoldOutlined
                        : MenuFoldOutlined,
                      {
                        className: "trigger",
                        onClick: this.toggle,
                      }
                    )}
                  </div>
                  <div className="ml-auto dropdown ">
                    <LogoutOutlined className="icons" onClick={this.onLogout} />
                  </div>
                </div>
              </Header>
              <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/orders" component={Orders} />
                <Route exact path="/orders/:id" component={SendPayemnt} />
                <Route
                  exact
                  path="/businesslisting"
                  component={BusinessListing}
                />
                <Route
                  exact
                  path="/businessrequests"
                  component={BusinessRequests}
                />
                <Route exact path="/blogs" component={Blogs} />
                <Route exact path="/transactions" component={Transactions} />
                <Route exact path="/users" component={Users} />
                <Route exact path="/userrequests" component={UserRequests} />
                <Route exact path="/Contactus" component={ContactForms} />
                <Route
                  exact
                  path="/featuredlisting"
                  component={FeaturedListing}
                />
                <Route exact path="/Hirings" component={Hiring} />
                <Route exact path="/profile" component={Profile} />
                <Redirect to="/dashboard" />
              </Switch>
            </Layout>
          </Layout>
        </>
      ) : (
        <Redirect to="/" />
      )
    );
  }
}

export default Sidebar;
