import React from "react";
import { message, Switch, Input } from "antd";
import axios from "axios";
import UploadComponent from "../UploadComponent";
import BusinessListingComponent from "./BusinessListingComponent";
import Map from "./Map";
import Geocode from "react-geocode";
import { InstagramFilled, FacebookFilled } from "@ant-design/icons";
import { ReactComponent as DeleteIcon } from "../../assests/delete.svg";
import TimeSlot from "./TimeSlot";
import ReactPhoneInput from "react-phone-input-2";

class BusinessListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      file: "",
      first_name: "",
      last_name: "",
      email: "",
      contact: "",
      password: "",
      vendorType: "",
      business_name: "",
      payment_type: "",
      website: "",
      city: "",
      State: "",
      address_line_1: "",
      lat: "",
      lng: "",
      lati: "",
      long: "",
      mapPosition: {
        lat: "",
        lng: "",
      },
      markerPosition: {
        lat: "",
        lng: "",
      },
      service_name: "",
      service_price: "",
      service_time: "",
      services: [],
      filteredServices: [],
      working_hours: {
        mon: {
          closed: false,
          time: [],
        },
        tue: {
          closed: false,
          time: [],
        },
        wed: {
          closed: false,
          time: [],
        },
        thu: {
          closed: false,
          time: [],
        },
        fri: {
          closed: false,
          time: [],
        },
        sat: {
          closed: false,
          time: [],
        },
        sun: {
          closed: false,
          time: [],
        },
      },
      WiFi: false,
      Book: false,
      trevel_to_me: false,
      Facebook: "",
      Instagram: "",
      BusinessListingData: [],
      error: "",
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.monTimeUpdate = this.monTimeUpdate.bind(this);
    this.tueTimeUpdate = this.tueTimeUpdate.bind(this);
    this.wedTimeUpdate = this.wedTimeUpdate.bind(this);
    this.thurTimeUpdate = this.thurTimeUpdate.bind(this);
    this.friTimeUpdate = this.friTimeUpdate.bind(this);
    this.satTimeUpdate = this.satTimeUpdate.bind(this);
    this.sunTimeUpdate = this.sunTimeUpdate.bind(this);
    this.validation = this.validation.bind(this);
  }

  componentDidMount = () => {
    this.getAllVenders();
    Geocode.fromLatLng(
      this.state.markerPosition.lat,
      this.state.markerPosition.lng
    ).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          //   area = this.getArea( addressArray ),
          state = this.getState(addressArray);

        this.setState({
          address_line_1: address ? address : "",
          // area: ( area ) ? area : '',
          city: city ? city : "",
          State: state ? state : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          //   area = this.getArea( addressArray ),
          state = this.getState(addressArray);
        this.setState({
          address_line_1: address ? address : "",
          // area: ( area ) ? area : '',
          city: city ? city : "",
          State: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  onPlaceChange = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      //   area = this.getArea( addressArray ),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.
    this.setState({
      address_line_1: address ? address : "",
      // area: ( area ) ? area : '',
      city: city ? city : "",
      State: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getState = (addressArray) => {
    let State = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          State = addressArray[i].long_name;
          return State;
        }
      }
    }
  };

  makeImageArray = (img) => {
    this.state.images.push(img);
  };

  imagesSet = (imageArray) => {
    this.setState({ images: [] });
    // eslint-disable-next-line
    imageArray.map((detail) => {
      this.makeImageArray(detail.originFileObj);
    });
  };

  getAllVenders = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/all_vendor_details`)
      .then((response) => {
        // eslint-disable-next-line
        if (response.data.success === true) {
          this.setState({
            BusinessListingData: response.data.data,
          });
        }
        // eslint-disable-next-line
        else if (response.data.success === false) {
          message.error(response.data.message);
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  onHandleChange = (event) => {
    const { name, value } = event.target;
    if (event.target.name === "first_name") {
      this.setState({
        first_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "last_name") {
      this.setState({
        last_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "city") {
      this.setState({
        city: event.target.value,
      });
    } else if (event.target.name === "state") {
      this.setState({
        State: event.target.value,
      });
    } else if (event.target.name === "contact") {
      this.setState({
        contact: event.target.value.replace(/[^0-9]/gi, ""),
      });
    } else if (event.target.name === "service_name") {
      this.setState({
        service_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "service_price") {
      this.setState({
        service_price: event.target.value.replace(/[^0-9]/gi, ""),
      });
    } else if (event.target.name === "service_time") {
      this.setState({
        service_time: event.target.value.replace(/[^0-9]/gi, ""),
      });
    } else {
      this.setState({
        [name]: value,
        error: "",
      });
    }
  };

  onWiFiChange = (e) => {
    this.setState({
      WiFi: e,
      error: "",
    });
  };

  onBookChange = (e) => {
    this.setState({
      Book: e,
      error: "",
    });
  };

  onTravelChange = (e) => {
    this.setState({
      trevel_to_me: e,
      error: "",
    });
  };

  monTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          mon: {
            ...this.state.working_hours.mon,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          mon: {
            ...this.state.working_hours.mon,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  tueTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          tue: {
            ...this.state.working_hours.tue,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          tue: {
            ...this.state.working_hours.tue,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  wedTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          wed: {
            ...this.state.working_hours.wed,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          wed: {
            ...this.state.working_hours.wed,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  thurTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          thu: {
            ...this.state.working_hours.thu,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          thu: {
            ...this.state.working_hours.thu,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  friTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          fri: {
            ...this.state.working_hours.fri,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          fri: {
            ...this.state.working_hours.fri,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  satTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          sat: {
            ...this.state.working_hours.sat,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          sat: {
            ...this.state.working_hours.sat,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  sunTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          sun: {
            ...this.state.working_hours.sun,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          sun: {
            ...this.state.working_hours.sun,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  Service = () => {
    // eslint-disable-next-line
    if (
      this.state.service_name !== "" &&
      this.state.service_price !== "" &&
      this.state.service_time !== ""
    ) {
      const random = Math.floor(100000 + Math.random() * 900000);
      const newService = {
        serviceid: random,
        service_name: this.state.service_name,
        service_price: this.state.service_price,
        service_time: parseInt(this.state.service_time),
      };
      this.state.services.push(newService);
      this.setState({
        service_name: "",
        service_price: "",
        service_time: "",
      });
    }
  };

  onUpdateName = (event) => {
    const id = event.target.id.replace("service_name", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid === id) {
        array = this.state.services;
        array[index].service_name = event.target.value;
        this.setState({
          services: array,
        });
      }
    });
  };

  onUpdatePrice = (event) => {
    const id1 = event.target.id.replace("service_price", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid === id1) {
        array = this.state.services;
        array[index].service_price = event.target.value.replace(/[^0-9]/gi, "");
        this.setState({
          services: array,
        });
      }
    });
  };

  onUpdatetime = (event) => {
    const id1 = event.target.id.replace("service_time", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid === id1) {
        array = this.state.services;
        array[index].service_time = parseInt(event.target.value).replace(
          /[^0-9]/gi,
          ""
        );
        this.setState({
          services: array,
        });
      }
    });
  };

  removeService(e) {
    // idToRemove = e;

    let myArr = this.state.services.filter(function (item) {
      return item.serviceid !== e;
    });

    this.setState({ services: myArr });
  }

  validation = () => {
    if (this.state.first_name !== "") {
      if (this.state.last_name !== "") {
        if (this.state.email !== "") {
          if (
            this.state.email.includes("@") &&
            this.state.email.includes(".")
          ) {
            if (this.state.password !== "") {
              if (this.state.payment_type !== "") {
                if (this.state.business_name !== "") {
                  if (this.state.city !== "") {
                    if (this.state.State !== "") {
                      if (this.state.address_line_1 !== "") {
                        // eslint-disable-next-line
                        if (
                          (this.state.working_hours.mon.closed === false &&
                            this.state.working_hours.mon.time.length !== 0) ||
                          this.state.working_hours.mon.closed === true
                        ) {
                          // eslint-disable-next-line
                          if (
                            (this.state.working_hours.tue.closed === false &&
                              this.state.working_hours.tue.time.length !== 0) ||
                            this.state.working_hours.tue.closed === true
                          ) {
                            // eslint-disable-next-line
                            if (
                              (this.state.working_hours.wed.closed === false &&
                                this.state.working_hours.wed.time.length !==
                                  0) ||
                              this.state.working_hours.wed.closed === true
                            ) {
                              // eslint-disable-next-line
                              if (
                                (this.state.working_hours.thu.closed ===
                                  false &&
                                  this.state.working_hours.thu.time.length !==
                                    0) ||
                                this.state.working_hours.thu.closed === true
                              ) {
                                // eslint-disable-next-line
                                if (
                                  (this.state.working_hours.fri.closed ===
                                    false &&
                                    this.state.working_hours.fri.time.length !==
                                      0) ||
                                  this.state.working_hours.fri.closed === true
                                ) {
                                  // eslint-disable-next-line
                                  if (
                                    (this.state.working_hours.sat.closed ===
                                      false &&
                                      this.state.working_hours.sat.time
                                        .length !== 0) ||
                                    this.state.working_hours.sat.closed === true
                                  ) {
                                    // eslint-disable-next-line
                                    if (
                                      (this.state.working_hours.sun.closed ===
                                        false &&
                                        this.state.working_hours.sun.time
                                          .length !== 0) ||
                                      this.state.working_hours.sun.closed ===
                                        true
                                    ) {
                                      // eslint-disable-next-line
                                      if (
                                        this.state.service_name === "" &&
                                        this.state.service_price === "" &&
                                        this.state.services.length > 0
                                      ) {
                                        if (
                                          this.state.images.length !== 0 &&
                                          this.state.images.length !== 1 &&
                                          this.state.images.length !== 2 &&
                                          this.state.images.length !== 3
                                        ) {
                                          // eslint-disable-next-line
                                          this.state.filteredServices = [];
                                          // eslint-disable-next-line
                                          this.state.services.map((service) => {
                                            if (
                                              service.service_name !== "" &&
                                              service.service_price !== ""
                                            ) {
                                              this.state.filteredServices.push(
                                                service
                                              );
                                            } else {
                                              message.error(
                                                "Incomplete service information will Discard the Service"
                                              );
                                            }
                                          });
                                          this.SignUp();
                                        } else if (
                                          this.state.images.length <= 3
                                        ) {
                                          this.setState({
                                            error:
                                              "Atleast 4 images are Required",
                                          });
                                          message.error(
                                            "Atleast 4 images are Required"
                                          );
                                        }
                                      } else if (
                                        this.state.service_name !== "" &&
                                        this.state.service_price !== ""
                                      ) {
                                        this.Service();
                                        if (
                                          this.state.images.length !== 0 &&
                                          this.state.images.length !== 1 &&
                                          this.state.images.length !== 2 &&
                                          this.state.images.length !== 3
                                        ) {
                                          // eslint-disable-next-line
                                          this.state.filteredServices = [];
                                          // eslint-disable-next-line
                                          this.state.services.map((service) => {
                                            if (
                                              service.service_name !== "" &&
                                              service.service_price !== ""
                                            ) {
                                              this.state.filteredServices.push(
                                                service
                                              );
                                            } else {
                                              message.error(
                                                "Incomplete service information will Discard the Service"
                                              );
                                            }
                                          });

                                          this.SignUp();
                                        } else if (
                                          this.state.images.length <= 3
                                        ) {
                                          this.setState({
                                            error:
                                              "Atleast 4 images are Required",
                                          });
                                          message.error(
                                            "Atleast 4 images are Required"
                                          );
                                        }
                                      }
                                      // eslint-disable-next-line
                                      else if (
                                        this.state.service_name === "" ||
                                        (this.state.service_price === "" &&
                                          this.state.services.length === 0)
                                      ) {
                                        this.setState({
                                          error: "Services are Required",
                                        });
                                        message.error("Services are Required");
                                      }
                                    } else {
                                      this.setState({
                                        error:
                                          "Sunday's Time should be Selected Properly",
                                      });
                                      message.error(
                                        "Sunday's Time should be Selected Properly"
                                      );
                                    }
                                  } else {
                                    this.setState({
                                      error:
                                        "Saturday's Time should be Selected Properly",
                                    });
                                    message.error(
                                      "Saturday's Time should be Selected Properly"
                                    );
                                  }
                                } else {
                                  this.setState({
                                    error:
                                      "Friday's Time should be Selected Properly",
                                  });
                                  message.error(
                                    "Friday's Time should be Selected Properly"
                                  );
                                }
                              } else {
                                this.setState({
                                  error:
                                    "Thursday's Time should be Selected Properly",
                                });
                                message.error(
                                  "Thursday's Time should be Selected Properly"
                                );
                              }
                            } else {
                              this.setState({
                                error:
                                  "Wednesday's Time should be Selected Properly",
                              });
                              message.error(
                                "Wednesday's Time should be Selected Properly"
                              );
                            }
                          } else {
                            this.setState({
                              error:
                                "Tuesday's Time should be Selected Properly",
                            });
                            message.error(
                              "Tuesday's Time should be Selected Properly"
                            );
                          }
                        } else {
                          this.setState({
                            error: "Monday's Time should be Selected Properly",
                          });
                          message.error(
                            "Monday's Time should be Selected Properly"
                          );
                        }
                      } else if (this.state.address_line_1 === "") {
                        this.setState({
                          error: "Address is Required",
                        });
                        message.error("Address is Required");
                      }
                    } else if (this.state.State === "") {
                      this.setState({
                        error: "State is Required",
                      });
                      message.error("State is Required");
                    }
                  } else if (this.state.city === "") {
                    this.setState({
                      error: "City is Required",
                    });
                    message.error("City is Required");
                  }
                } else if (this.state.business_name === "") {
                  this.setState({
                    error: "Business Name is Required",
                  });
                  message.error("Business Name is Required");
                }
              } else if (this.state.payment_type === "") {
                this.setState({
                  error: "Payment Type Must be Selected",
                });
                message.error("Payment Type Must be Selected");
              }
            } else if (this.state.password === "") {
              this.setState({
                error: "Password is Required",
              });
              message.error("Password is Required");
            }
          } else {
            this.setState({
              error: "Email is not valid",
            });
            message.error("Email is not valid");
          }
        } else if (this.state.email === "") {
          this.setState({
            error: "Email is Required",
          });
          message.error("Email is Required");
        }
      } else if (this.state.last_name === "") {
        this.setState({
          error: "Last Name is Required",
        });
        message.error("Last Name is Required");
      }
    } else if (this.state.first_name === "") {
      this.setState({
        error: "First Name is Required",
      });
      message.error("First Name is Required");
    }
  };

  SignUp = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/admin/register_vendor`, {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        password: this.state.password,
        contact: this.state.contact,
        payment_type: this.state.payment_type,
        business_name: this.state.business_name,
        website: this.state.website,
        city: this.state.city,
        State: this.state.State,
        address_line_1: this.state.address_line_1,
        lat: this.state.mapPosition.lat,
        lng: this.state.mapPosition.lng,
        services: this.state.filteredServices,
        working_hours: this.state.working_hours,
        wifi: this.state.WiFi,
        instant_booking: this.state.Book,
        trevel_to_me: this.state.trevel_to_me,
        facebook_link: this.state.Facebook,
        instagram_link: this.state.Instagram,
      })
      .then((response) => {
        // eslint-disable-next-line
        if (response.data.success === true) {
          this.sendImages(response.data.data._id);
        }
        // eslint-disable-next-line
        else if (response.data.success === false) {
          message.error(response.data.message);
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  sendImages = (id) => {
    let formData = new FormData();
    // eslint-disable-next-line
    this.state.images.map((img) => {
      formData.append("images", img);
    });
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/vendor/update_images/` + id,
        formData
      )
      .then((response) => {
        // eslint-disable-next-line
        if (response.data.success === true) {
          message.success("Registered");
        }
        // eslint-disable-next-line
        else if (response.data.success === false) {
          message.error(response.data.message);
        }
      })
      .catch(function (error) {
        message.error(error);
      });
  };

  render() {
    const RenderServices = this.state.services.map((servicemap) => {
      return (
        <>
          <div className=" col-sm-4 col-12 my-2">
            <input
              type="text"
              className="form-control Radius_10"
              name="service_name"
              id={servicemap.serviceid}
              value={servicemap.service_name}
              onChange={this.onUpdateName}
              placeholder="Service Name"
            />
          </div>
          <div className=" col-sm-4 col-12 my-2">
            <input
              type="text"
              className="form-control Radius_10"
              name="service_price"
              id={servicemap.serviceid}
              value={servicemap.service_price}
              onChange={this.onUpdatePrice}
              placeholder="Service Price"
            />
          </div>
          <div className="col-12 col-sm-2 my-2">
            <input
              type="text"
              className=" form-control Radius_10"
              name="service_time"
              id={servicemap.serviceid + "service_time"}
              value={servicemap.service_time}
              onChange={this.onUpdatetime}
              placeholder="time"
            />
          </div>
          <div className="col-12 col-sm-2 my-2">
            <button
              type="button"
              className="btn mb-5 mb-sm-0 d-block mx-auto"
              onClick={() => this.removeService(servicemap.serviceid)}
            >
              {" "}
              <DeleteIcon style={{ height: "24px", width: "24px" }} />{" "}
            </button>
          </div>
        </>
      );
    });
    return (
      <>
        <div className=" col-11 mx-auto pt-3 ">
          <h4 className=" Bold">Business Listing </h4>
          <h6 className=" Bold pt-2">Personal Information</h6>
          <form>
            <div className="row">
              <div className="col-12 col-sm-6 my-1">
                <input
                  type="text"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="First Name Required"
                  className="form-control Radius_10 "
                  placeholder="First name"
                  name="first_name"
                  value={this.state.first_name}
                  onChange={this.onHandleChange}
                />
              </div>
              <div className="col-12 col-sm-6 my-1">
                <input
                  type="text"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Last Name Required"
                  className="form-control Radius_10 "
                  placeholder="Last name"
                  name="last_name"
                  value={this.state.last_name}
                  onChange={this.onHandleChange}
                />
              </div>
              <div className="col-12 col-sm-6 my-1">
                <input
                  type="email"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Email Required"
                  className="form-control Radius_10 "
                  placeholder="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onHandleChange}
                />
              </div>

              <div className="col-12 col-sm-6 my-1">
                <ReactPhoneInput
                  inputExtraProps={{
                    name: "contact",
                    required: true,
                    autoFocus: true,
                  }}
                  containerClass=""
                  defaultCountry={"sg"}
                  value={this.state.contact}
                  onChange={(text) => this.setState({ contact: text })}
                  specialLabel={false}
                  inputClass="form-control Radius_10"
                />
                {/* <input type="text" data-toggle="tooltip" data-placement="left" title="Contact Required"
                                    className="form-control Radius_10 "
                                    placeholder="Phone"
                                    name="contact"
                                    value={this.state.contact}
                                    onChange={this.onHandleChange} /> */}
              </div>
              <div className="col-12 col-sm-6 my-1">
                <input
                  type="password"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Password Must be minimum 6 digits"
                  className="form-control Radius_10"
                  placeholder="Password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onHandleChange}
                />
              </div>
            </div>
          </form>

          <h6 className="mt-2 Bold">Payment Type</h6>
          <div className="col-12 px-0">
            <ul className="col-12 col-md-12 mx-auto combineButton form RadioToButton d-inline-flex px-0">
              <li className="w-50">
                <input
                  type="radio"
                  id="full"
                  name="payment_type"
                  value="full"
                  onClick={this.onHandleChange}
                />
                <label for="full" className="py-2 mb-0 Bold">
                  Full
                </label>
              </li>
              <li className="w-50">
                <input
                  type="radio"
                  id="deposit"
                  name="payment_type"
                  value="deposit"
                  onClick={this.onHandleChange}
                />
                <label for="deposit" className="py-2 mb-0 Bold">
                  Deposit
                </label>
              </li>
              <li className="w-50">
                <input
                  type="radio"
                  id="both"
                  name="payment_type"
                  value="both"
                  onClick={this.onHandleChange}
                />
                <label for="both" className="py-2 mb-0 Bold">
                  Both
                </label>
              </li>
            </ul>
          </div>

          <h6 className="mt-4 mb-2 Bold">Business Information</h6>

          <form>
            <div className="row">
              <div className="col-12 col-md-6 my-1">
                <input
                  type="text"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Business Name Required"
                  className="form-control Radius_10"
                  name="business_name"
                  placeholder="Business Name"
                  value={this.state.business_name}
                  onChange={this.onHandleChange}
                />
              </div>
              <div className="col-12 col-md-6 my-1">
                <input
                  type="url"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Business Website Required"
                  className="form-control Radius_10"
                  name="website"
                  placeholder="Business Website"
                  value={this.state.website}
                  onChange={this.onHandleChange}
                />
              </div>
              <div className="col-12  my-1">
                <input
                  type="text"
                  name="address_line_1"
                  className="form-control Radius_10"
                  onChange={this.onHandleChange}
                  value={this.state.address_line_1}
                  placeholder="Address"
                />
              </div>
              <div className="col-12">
                <Map
                  onChange={this.onPlaceChange}
                  google={this.props.google}
                  center={{
                    lat: 40.711391223589956,
                    lng: -74.00283887121081,
                  }}
                  funt={this.onMarkerDragEnd}
                  city={this.state.city}
                  State={this.state.State}
                  addressChange={this.onHandleChange}
                  height="300px"
                  zoom={15}
                />
              </div>
            </div>
          </form>

          {/* Add Time */}
          <h6 className="mt-5 mb-2 Bold pt-3">
            Please Select All Opening Time Slots For Each Day
          </h6>
          <p>(No worries* No Book Time Slots will Overlap)</p>
          <div className="">
            <div className="row">
              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Monday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="closed"
                    value=""
                    onChange={(e) => this.monTimeUpdate("checkbox", e)}
                    id="MonClose"
                  />
                  <label className="form-check-label" for="MonClose">
                    Closed{" "}
                  </label>
                </div>
              </div>
              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    this.state.working_hours.mon.closed === true
                      ? "d-none"
                      : "show"
                  }`}
                >
                  <TimeSlot
                    selected={this.state.working_hours.mon.time}
                    handleTimeChange={this.monTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Tuesday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="closed"
                    id="tueClose"
                    onChange={(e) => this.tueTimeUpdate("checkbox", e)}
                  />
                  <label className="form-check-label" for="tueClose">
                    Closed
                  </label>
                </div>
              </div>
              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    this.state.working_hours.tue.closed === true
                      ? "d-none"
                      : "show"
                  }`}
                >
                  <TimeSlot
                    selected={this.state.working_hours.tue.time}
                    handleTimeChange={this.tueTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Wednesday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="closed"
                    id="wedClose"
                    onChange={(e) => this.wedTimeUpdate("checkbox", e)}
                  />
                  <label className="form-check-label" for="wedClose">
                    Closed
                  </label>
                </div>
              </div>
              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    this.state.working_hours.wed.closed === true
                      ? "d-none"
                      : "show"
                  }`}
                >
                  <TimeSlot
                    selected={this.state.working_hours.wed.time}
                    handleTimeChange={this.wedTimeUpdate}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Thursday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="closed"
                    value=""
                    onChange={(e) => this.thurTimeUpdate("checkbox", e)}
                    id="thurClose"
                  />
                  <label className="form-check-label" for="thurClose">
                    Closed{" "}
                  </label>
                </div>
              </div>
              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    this.state.working_hours.thu.closed === true
                      ? "d-none"
                      : "show"
                  }`}
                >
                  <TimeSlot
                    selected={this.state.working_hours.thu.time}
                    handleTimeChange={this.thurTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Friday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="closed"
                    onChange={(e) => this.friTimeUpdate("checkbox", e)}
                    id="FriClose"
                  />
                  <label className="form-check-label" for="FriClose">
                    Closed
                  </label>
                </div>
              </div>
              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    this.state.working_hours.fri.closed === true
                      ? "d-none"
                      : "show"
                  }`}
                >
                  <TimeSlot
                    selected={this.state.working_hours.fri.time}
                    handleTimeChange={this.friTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Saturday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="closed"
                    onChange={(e) => this.satTimeUpdate("checkbox", e)}
                    id="satClose"
                  />
                  <label className="form-check-label" for="satClose">
                    Closed
                  </label>
                </div>
              </div>
              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    this.state.working_hours.sat.closed === true
                      ? "d-none"
                      : "show"
                  }`}
                >
                  <TimeSlot
                    selected={this.state.working_hours.sat.time}
                    handleTimeChange={this.satTimeUpdate}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Sunday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="closed"
                    onChange={(e) => this.sunTimeUpdate("checkbox", e)}
                    id="sunClose"
                  />
                  <label className="form-check-label" for="sunClose">
                    Closed
                  </label>
                </div>
              </div>

              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    this.state.working_hours.sun.closed === true
                      ? "d-none"
                      : "show"
                  }`}
                >
                  <TimeSlot
                    selected={this.state.working_hours.sun.time}
                    handleTimeChange={this.sunTimeUpdate}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Add Service Menu Form */}
          <h5 className=" mt-4">Add Service Menu</h5>
          <form>
            <div className="row">
              {RenderServices}
              <div className="col-12 col-sm-5 my-2">
                <input
                  type="text"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Service Name Required"
                  className="form-control Radius_10"
                  name="service_name"
                  placeholder="Service Name"
                  value={this.state.service_name}
                  onChange={this.onHandleChange}
                />
              </div>
              <div className="col-12 col-sm-5 my-2">
                <input
                  type="text"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Service Price Required"
                  className="form-control Radius_10"
                  name="service_price"
                  placeholder="Service Price in $!"
                  value={this.state.service_price}
                  onChange={this.onHandleChange}
                />
              </div>
              <div className="col-12 col-sm-2 my-2">
                <input
                  type="number"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Service Time Required"
                  className="form-control Radius_10"
                  name="service_time"
                  placeholder="Service Time in minutes!"
                  value={this.state.service_time}
                  onChange={this.onHandleChange}
                />
              </div>
            </div>
            <div className=" col-12">
              <button
                type="button"
                className="btn rounded-circle d-block mx-auto Orange White bg-dark mt-4 "
                onClick={this.Service}
              >
                +
              </button>
            </div>
            <div>
              <h5 className="mt-4">Upload Images</h5>
              <p>* only png, jpeg, jpg files accepted</p>
              <div className="row col-11 my-auto mx-auto">
                <div className="row col-md-3 col-sm-6 col-lg-4 col-12">
                  <UploadComponent
                    imagesSet={this.imagesSet}
                    limit={7}
                    display={this.state.images}
                  />
                </div>
              </div>
              <h6 className="mt-4 mb-2 Bold">Amenities</h6>
              <div className="form-group row">
                <label for="Book" className="font16 col-8">
                  Instant Booking
                </label>
                <div className="col-4">
                  <Switch
                    id="Book"
                    unCheckedChildren="OFF"
                    checkedChildren="ON"
                    checked={this.state.Book}
                    onChange={this.onBookChange}
                    className="ml-auto"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label for="WiFI" className="font16 col-8">
                  Wi-Fi
                </label>
                <div className="col-4">
                  <Switch
                    id="WiFi"
                    unCheckedChildren="OFF"
                    checkedChildren="ON"
                    checked={this.state.WiFi}
                    onChange={this.onWiFiChange}
                    className="ml-auto"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label for="Travel" className="font16 col-8">
                  Offer Services at door Step
                </label>
                <div className="col-4 my-auto">
                  <Switch
                    id="Travel"
                    unCheckedChildren="OFF"
                    checkedChildren="ON"
                    checked={this.state.trevel_to_me}
                    onChange={this.onTravelChange}
                    className="ml-auto"
                  />
                </div>
              </div>
              <h6 className="mt-4 mb-2 Bold">Social Links</h6>
              <div className="form-group">
                <Input
                  size="large"
                  className="col-11 col-md-7 mb-2"
                  placeholder="Facebook Link"
                  name="Facebook"
                  onChange={this.onHandleChange}
                  prefix={<FacebookFilled />}
                />
                <Input
                  size="large"
                  className="col-11 col-md-7 mb-2"
                  placeholder="Instagram Link"
                  name="Instagram"
                  onChange={this.onHandleChange}
                  prefix={<InstagramFilled />}
                />
              </div>

              <button
                className="btn White skyblue d-block mx-auto mt-4"
                onClick={this.validation}
                type="button"
              >
                Submit Request
              </button>
              <p
                className="text-danger text-center mb-0py-2"
                style={{ fontSize: "20px", fontWeight: "500" }}
              >
                {" "}
                {this.state.error}
              </p>
            </div>
          </form>
          <hr></hr>
          <div className="pt-3">
            <h4>Registered Businesses</h4>
            {this.state.BusinessListingData.length > 0 ? (
              <>
                <BusinessListingComponent
                  data={this.state.BusinessListingData}
                  getAllVenders={this.getAllVenders}
                />
              </>
            ) : (
              <div className="container h-100 center Pt25">
                <div className="my-auto px-auto h-100 d-block">
                  <div className="d-flex h-100 justify-content-center">
                    <div className="my-auto">
                      <p>No Data Available</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default BusinessListing;
