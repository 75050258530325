import React from "react";
import { Space, Table, message } from "antd";
import axios from "axios";

export default function BusinessListingComponent(props) {
  const columns = [
    {
      title: "ID",
      render: (value) => value._id.slice(-4),
      sorter: {
        compare: (a, b) => a._id.localeCompare(b._id),
      },
    },
    {
      title: "Name",
      render: (value) => value.first_name + " " + value.last_name,
      sorter: {
        compare: (a, b) => a.first_name.localeCompare(b.first_name),
      },
    },
    {
      title: "Number",
      dataIndex: "contact",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Business Name",
      render: (render) => render.business_information.business_name,
      sorter: {
        compare: (a, b) =>
          a.business_information.business_name.localeCompare(
            b.business_information.business_name
          ),
      },
    },
    {
      title: "Business Website",
      render: (render) => render.business_information.website,
    },
    {
      title: "City",
      render: (render) => render.business_information.city,
    },
    {
      title: "State",
      render: (render) => render.business_information.State,
    },
    {
      title: "Service Name",
      render: (render) => (
        <div class="btn-group">
          <button
            class="btn btn-secondary btn-sm dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Services
          </button>
          <div class="dropdown-menu px-2">
            <span className="d-flex">
              <span className="mr-auto Bold">Name</span>
              <span className="ml-auto Bold">Price</span>
            </span>
            {render.services.map(function (name, index) {
              return (
                <span key={`demo_snap_${index}`} className="d-flex">
                  <span className="mr-auto">{name.service_name}</span>
                  <span className="ml-auto">{name.service_price}</span>
                </span>
              );
            })}
          </div>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => (
        <Space size="middle">
          <button
            type="button"
            className="btn btnClass"
            onClick={(e) => {
              deleteUser(record._id);
            }}
          >
            Delete
          </button>
        </Space>
      ),
    },
  ];

  const data = props.data;

  function onChange(pagination, filters, sorter, extra) {}

  const deleteUser = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/admin/delete_vendor/` + id)
      .then((response) => {
        if (response.data.success) {
          message.success("Deleted Successfull");
          props.getAllVenders();
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      className="table-responsive"
    />
  );
}
