import React from "react";
import { Space, Table, message } from "antd";
import axios from "axios";

export default function UserComponent(props) {
  const columns = [
    {
      title: "ID",
      render: (value) => value._id.slice(-4),
      sorter: {
        compare: (a, b) => a._id.localeCompare(b._id),
      },
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      sorter: {
        compare: (a, b) => a.first_name.localeCompare(b.first_name),
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
    },
    // {
    //     title: 'Phone',
    //     dataIndex: 'contact',
    // },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Actions",
      dataIndex: "_id",
      key: "x",
      render: (text, record, index) => (
        <Space size="middle">
          <button
            type="button"
            className="btn btnClass"
            onClick={(e) => {
              deleteUser(record._id);
            }}
          >
            Delete
          </button>
        </Space>
      ),
    },
  ];

  const data = props.data;

  function onChange(pagination, filters, sorter, extra) {}

  const deleteUser = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/admin/delete_user/` + id)
      .then((response) => {
        if (response.data.success) {
          message.success("Deleted Successfull");
          props.getUserDetail();
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      className="table-responsive"
    />
  );
}
