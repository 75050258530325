import React from "react";
import { Table } from "antd";

export default function FeaturedListingComponent(props) {
  const columns = [
    {
      title: "ID",
      render: (value) => value._id.slice(-4),
      sorter: {
        compare: (a, b) => a._id.localeCompare(b._id),
      },
    },
    {
      title: "Buisness Name",
      render: (render) => render.business_information.business_name,
      sorter: {
        compare: (a, b) =>
          a.business_information.business_name.localeCompare(
            b.business_information.business_name
          ),
      },
    },
    {
      title: "Business Website",
      render: (render) => render.business_information.website,
    },
    {
      title: "City",
      render: (render) => render.business_information.city,
    },
    {
      title: "State",
      render: (render) => render.business_information.State,
    },
    {
      title: "Status",
      render: (render) => <>Featured</>,
    },
  ];

  const data = props.data;

  function onChange(pagination, filters, sorter, extra) {}
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      className="table-responsive"
    />
  );
}
