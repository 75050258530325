import React from "react";
import { Table } from "antd";

export default function TransactionComponent(props) {
  const columns = [
    {
      title: "ID",
      render: (value) => value.id.slice(-4),
      sorter: {
        compare: (a, b) => a.id.localeCompare(b.id),
      },
    },
    {
      title: "User Name",
      key: "name",
      render: (record) => (
        <>
          {record?.booking?.user?.first_name +
            " " +
            record?.booking?.user?.last_name}
        </>
      ),
    },
    {
      title: "PayPal Email/Id",
      Key: "paypal",
      render: (record) => {
        if (record?.paypalResponseData) {
          let value = JSON.parse(record?.paypalResponseData);
          return (
            <>
              {value?.details?.payer?.email_address ||
                value?.details?.subscriber?.email_address}
            </>
          );
        } else if (record?.paymentResponseData) {
          let value = JSON.parse(record?.paymentResponseData);
          return <>{value.items[0].payout_item.receiver}</>;
        }
      },
    },
    {
      title: "Transaction Type",
      key: "transactionType",
      render: (record) => <>{record?.transactionType}</>,
    },
    {
      title: "Type",
      key: "type",
      render: (record) => (
        <>
          {record.type === "booking-created" &&
            `${record?.type} (user to admin)`}
          {record.type === "booking-completed" &&
            `${record?.type} (admin to vendor)`}
          {record.type === "booking-cancelled" &&
            `${record?.type} (admin to user)`}
        </>
      ),
    },
    {
      title: "Vendor Name",
      key: "vendorname",
      sorter: (a, b) =>
        a?.booking?.vendor?.first_name.localeCompare(
          b?.booking?.vendor?.first_name
        ),
      render: (record) => (
        <>
          {record?.booking?.vendor?.first_name +
            " " +
            record?.booking?.vendor?.last_name}
        </>
      ),
    },
    {
      title: "Vendor Bussiness",
      key: "vendorbusiness",
      render: (record) => <>{record?.booking?.vendor?.business_name}</>,
    },
    {
      title: "Total Bill",
      key: "totalbill",
      render: (record) => <>{record?.booking?.total_bill}</>,
    },
    {
      title: "Send Amount",
      key: "totaldeposite",
      render: (record) => {
        if (record.paypalResponseData) {
          const value = JSON.parse(record.paypalResponseData);
          return value?.batch_header?.amount?.value;
        } else {
          return record?.booking?.total_deposit;
        }
      },
    },
    {
      title: "Booking",
      key: "booking",

      render: (record) => (
        <>{record?.booking?.instant_booking ? "Instant" : "Normal"}</>
      ),
    },
    {
      title: "Source",
      Key: "source",
      render: (record) => {
        if (record?.paypalResponseData) {
          let value = JSON.parse(record?.paypalResponseData);
          return <>{value?.data?.paymentSource}</>;
        }
      },
    },
  ];

  const data = props.data;

  function onChange(pagination, filters, sorter, extra) {}
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      className="table-responsive"
    />
  );
}
