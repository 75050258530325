import React, { useEffect, useState } from "react";
import OrderComponent from "./OrderComponent";
import { message } from "antd";
import axios from "axios";
const Orders = () => {
  const initialstate = {
    OrderData: [],
  };
  const [FormData, setFormData] = useState(initialstate);
  const { OrderData } = FormData;

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line
  }, []);

  const getOrders = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/all_bookings`)
      .then((response) => {
        if (response.data.success) {
          setFormData({
            ...FormData,
            OrderData: response.data.data,
          });
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  return (
    <>
      <div className="row mr-0">
        <div className="col-11 mx-auto pt-3">
          <h6>Orders</h6>
          <OrderComponent data={OrderData} />
        </div>
      </div>
    </>
  );
};

export default Orders;
