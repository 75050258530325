import React, { useEffect, useState } from "react";
import UserComponent from "./UserComponent";
import { message } from "antd";
import $ from "jquery";
import axios from "axios";

const Users = () => {
  const initialstate = {
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    password: "",
    error: "",
    UserData: [],
  };
  const [FormData, setFormData] = useState(initialstate);
  const { first_name, last_name, email, contact, password, error, UserData } =
    FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
      error: "",
    });
  };

  // eslint-disable-next-line
  useEffect(() => {
    getUserDetail();
    // eslint-disable-next-line
  }, []);

  const getUserDetail = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/all_user_details`)
      .then((response) => {
        if (response.data.success) {
          setFormData({
            ...FormData,
            UserData: response.data.data,
          });
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  const AddUser = () => {
    if (first_name !== "") {
      if (last_name !== "") {
        if (email !== "") {
          if (email.includes("@") && email.includes(".")) {
            if (contact !== "") {
              if (password !== "") {
                axios
                  .post(`${process.env.REACT_APP_API_URL}/api/user/signup`, {
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    contact: contact,
                    password: password,
                  })
                  .then((response) => {
                    if (response.data.success) {
                      Empty();
                      message.success("Added Successfull");
                      getUserDetail();
                    }
                    // eslint-disable-next-line
                    else if (response.data.success == false) {
                      message.error(response.data.message);
                    }
                  });
              } else if (password === "") {
                setFormData({
                  ...FormData,
                  error: "Password is Required",
                });
                message.error("Password is Required");
              }
            } else if (contact === "") {
              setFormData({
                ...FormData,
                error: "Contact is Required",
              });
              message.error("Contact is Required");
            }
          } else {
            setFormData({
              ...FormData,
              error: "Email is not valid",
            });
            message.error("Email is not valid");
          }
        } else if (email === "") {
          setFormData({
            ...FormData,
            error: "Email is Required",
          });
          message.error("Email is Required");
        }
      } else if (last_name === "") {
        setFormData({
          ...FormData,
          error: "Last Name is Required",
        });
        message.error("Last Name is Required");
      }
    } else if (first_name === "") {
      setFormData({
        ...FormData,
        error: "First Name is Required",
      });
      message.error("First Name is Required");
    }
  };

  const Empty = () => {
    $("input").val("");
  };
  return (
    <>
      <div className="row pt-3 mr-0">
        <div className="col-11 mx-auto">
          <form>
            <h3>Users</h3>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <input
                  type="text"
                  className="form-control Radius_10 m-1"
                  placeholder="First name"
                  name="first_name"
                  onChange={onHandleChange}
                ></input>
              </div>
              <div className="col-xs-12 col-sm-6">
                <input
                  type="text"
                  className="form-control Radius_10 m-1"
                  placeholder="Last name"
                  name="last_name"
                  onChange={onHandleChange}
                ></input>
              </div>
              <div className="col-xs-12 col-sm-6">
                <input
                  type="email"
                  className="form-control Radius_10 m-1"
                  placeholder="Email"
                  name="email"
                  onChange={onHandleChange}
                ></input>
              </div>
              <div className="col-xs-12 col-sm-6">
                <input
                  type="phone"
                  className="form-control Radius_10 m-1"
                  placeholder="Phone"
                  name="contact"
                  onChange={onHandleChange}
                ></input>
              </div>
              <div className="col-xs-12 col-sm-6">
                <input
                  type="password"
                  className="form-control Radius_10 m-1"
                  placeholder="password"
                  name="password"
                  onChange={onHandleChange}
                ></input>
              </div>
            </div>
            <button
              className="btn White skyblue d-block mx-auto mt-4"
              onClick={AddUser}
              type="button"
            >
              Add User
            </button>
            <p
              className="text-danger text-center mb-0py-2"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              {" "}
              {error}
            </p>
          </form>
          <hr></hr>
          <div className="pt-3">
            <h4>All Users</h4>
            <UserComponent
              data={UserData}
              getUserDetail={getUserDetail}
            ></UserComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
