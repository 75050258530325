import React, { useEffect, useState } from "react";
import FeaturedListingComponent from "./FeaturedListingComponent";
import { message } from "antd";
import axios from "axios";

const FeaturedListings = () => {
  const initialstate = {
    FeatureData: [],
  };
  const [FormData, setFormData] = useState(initialstate);
  const { FeatureData } = FormData;

  useEffect(() => {
    getFeatureDetail();
    // eslint-disable-next-line
  }, []);

  const getFeatureDetail = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/vendor/featured_saloon`)
      .then((response) => {
        if (response.data.success) {
          setFormData({
            ...FormData,
            FeatureData: response.data.data,
          });
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  return (
    <>
      <div className="row mr-0">
        <div className="col-11 mx-auto pt-3">
          <h6>Featured List</h6>
          <FeaturedListingComponent
            data={FeatureData}
            getFeatureDetail={getFeatureDetail}
          />
        </div>
      </div>
    </>
  );
};

export default FeaturedListings;
