import React from "react";
import { Space, Table } from "antd";

export default function FormsComponent(props) {
  const columns = [
    {
      title: "ID",
      render: (value) => value._id.slice(-4),
      sorter: {
        compare: (a, b) => a._id.localeCompare(b._id),
      },
    },
    {
      title: "User Name",
      render: (value) => value.first_name + " " + value.last_name,
      sorter: {
        compare: (a, b) => a.first_name.localeCompare(b.first_name),
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => (
        <Space size="middle">
          <a
            className="Black mb-0"
            href={
              "mailto:" +
              record.email +
              "?subject=" +
              record.message +
              "&body=Hello " +
              record.first_name +
              ", Thank You for contacting Us"
            }
          >
            <button type="button" className="btn skyblue White">
              Reply
            </button>
          </a>
        </Space>
      ),
    },
  ];

  const data = props.data;

  function onChange(pagination, filters, sorter, extra) {}
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      className="table-responsive"
    />
  );
}
