import React from "react";
import { Table } from "antd";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";

export default function OrderComponent(props) {
  const columns = [
    {
      title: "ID",
      render: (value) => value._id.slice(-4),
      sorter: {
        compare: (a, b) => a._id.localeCompare(b._id),
      },
    },
    {
      title: "User Name",
      render: (value) => value.first_name + " " + value.last_name,
      sorter: {
        compare: (a, b) => a.first_name.localeCompare(b.first_name),
      },
    },
    {
      title: "Service Name",
      render: (render) =>
        render.cartItems.map(function (name, index) {
          return (
            <span key={`demo_snap_${index}`}>
              {(index ? ", " : "") + name.service_name}
            </span>
          );
        }),
    },
    {
      title: "Name",
      render: (value) => value.vendor.business_name,
      sorter: {
        compare: (a, b) =>
          a.vendor.business_name.localeCompare(b.vendor.business_name),
      },
    },
    {
      title: "Price",
      dataIndex: "total_bill",
      sorter: {
        compare: (a, b) => a.total_bill - b.total_bill,
      },
    },
    {
      title: "Date",
      render: (value) => dateFormat(value.booking_date, "mm/dd/yyyy"),
      sorter: {
        compare: (a, b) => a.booking_date.localeCompare(b.booking_date),
      },
    },
    {
      title: "Time",
      dataIndex: "booking_time",
      sorter: {
        compare: (a, b) => a.booking_time - b.booking_time,
      },
    },

    {
      title: "Status",
      dataIndex: "booking_status",
      sorter: {
        compare: (a, b) => a.booking_status.localeCompare(b.booking_status),
      },
    },

    {
      title: "Send Payment",
      sorter: {
        compare: (a, b) => a.booking_status.localeCompare(b.booking_status),
      },
      render: (value) => {
        if (value?.paymentSendByAdmin?.status === false) {
          return (
            <>
              <Link
                to={`/orders/${value?._id}`}
                className="btn White skyblue d-block mx-auto"
              >
                Send Payment
              </Link>
            </>
          );
        } else {
          return (
            <span className="d-flex mx-auto justify-content-center ">-</span>
          );
        }
      },
    },
  ];

  const data = props.data;

  function onChange(pagination, filters, sorter, extra) {}
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      className="table-responsive"
    />
  );
}
