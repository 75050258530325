import React, { useEffect, useState } from "react";
import BlogComponent from "./BlogComponent";
import UploadComponent from "../UploadComponent";
import { message, Modal, Button } from "antd";
import axios from "axios";

const Blogs = () => {
  const initialstate = {
    title: "",
    img: [],
    content: "",
    BlogData: [],
    error: "",
    isModalVisible: false,
  };

  const [formData, setformData] = useState(initialstate);
  const { content, title, img, BlogData, error, isModalVisible } = formData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setformData({
      ...formData,
      [name]: value,
      error: "",
    });
  };

  useEffect(() => {
    FetchBlogs();
    // eslint-disable-next-line
  }, []);

  const imagesSet = (imageArray) => {
    // eslint-disable-next-line
    imageArray.map((detail) => {
      setformData({
        ...formData,
        img: detail.originFileObj,
      });
    });
  };

  const FetchBlogs = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/get_blogs`)
      .then((response) => {
        if (response.data.success) {
          setformData({
            ...formData,
            BlogData: response.data.data,
          });
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };
  const validation = () => {
    if (title !== "") {
      if (img.length !== 0) {
        if (content !== "") {
          if (content.length > 10) {
            let forData = new FormData();
            forData.append("title", title);
            forData.append("description", content);
            forData.append("images", img);
            axios
              .post(`${process.env.REACT_APP_API_URL}/api/admin/blog`, forData)
              .then((response) => {
                if (response.data.success) {
                  message.success("Blog Added Succesfully");
                  FetchBlogs();
                  setformData({
                    ...formData,
                    error: "",
                    title: "",
                    img: [],
                    content: "",
                  });
                }
              })

              .catch(function (error) {
                console.log(error.data);
                // message.error(error);
              });
          } else {
            setformData({
              ...formData,
              error: "Description is must be atleast 10 Character long",
            });
          }
        } else {
          setformData({
            ...formData,
            error: "Description is Required",
          });
        }
      } else {
        setformData({
          ...formData,
          error: "Image is required",
        });
      }
    } else {
      setformData({
        ...formData,
        error: "Title is Required",
      });
    }
  };
  const showModal = () => {
    setformData({
      ...formData,
      isModalVisible: true,
    });
  };
  const handleOk = () => {
    setformData({
      ...formData,
      isModalVisible: false,
    });
  };
  const handleCancel = () => {
    setformData({
      ...formData,
      isModalVisible: false,
    });
  };
  return (
    <>
      <div className="row mr-0">
        <div className="col-11 mx-auto">
          <h2 className="text-center pt-3">Blogs</h2>
          <div className="col-md-4 col-12">
            <h4>Title</h4>
            <input
              type="text"
              className="form-control Radius_20"
              placeholder="Title Here"
              name="title"
              value={title}
              onChange={onHandleChange}
            ></input>
          </div>
          <h5 className="mt-4">Upload Images</h5>
          <div className="row col-11 my-auto mx-auto">
            <div className=" col-md-3 col-sm-6 col-lg-4 col-12">
              <UploadComponent imagesSet={imagesSet} limit={1} display={img} />
            </div>
            <div className="col-md-9 col-sm-6 col-lg-8 col-12">
              <div className="mt-3">
                <Button
                  type="primary"
                  onClick={showModal}
                  className="leftBottom"
                >
                  Edit Options
                </Button>
                <Modal
                  title="Edit Blog"
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <div className="row">
                    <p className="col-12">
                      <strong>For Link:</strong> your blog text then
                      "&lt;/p&gt;&lt;a href="https://your_Link"
                      target="/blank"&gt;Text for Link&lt;/a&gt;&lt;p&gt;" then
                      remaining text for blog
                    </p>
                    <p className="col-12">
                      <strong>Link Example:</strong> dummy text "&lt;/p&gt;&lt;a
                      href="https://www.google.com"
                      target="/blank"&gt;Google&lt;/a&gt;&lt;p&gt;" remaining
                      Dummy text
                    </p>

                    <p className="col-12">
                      <strong>For Heading:</strong> your blog text then
                      "&lt;h1&gt;Heading text&lt;/h1&gt;&lt;p&gt;" then
                      remaining text for blog
                    </p>
                    <p className="col-12">
                      <strong>Heading Example:</strong> Dummy Text
                      "&lt;h1&gt;Heading&lt;/h1&gt;&lt;p&gt;" Dummy text
                    </p>
                    <p className="col-12">
                      <strong>For Image:</strong> your blog text then "&lt;img
                      src="image link" /&gt;&lt;p&gt;" then remaining text for
                      blog
                    </p>
                    <p className="col-12">
                      <strong>Image Example:</strong> Dummy Text "&lt;img
                      src="image link" /&gt;&lt;p&gt;" Dummy text
                    </p>
                    <p>
                      You can add other styling by knowning attributes from{" "}
                      <a href="https://www.w3schools.com/" target="/blank">
                        w3school
                      </a>{" "}
                    </p>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          <div className="col-12 mx-auto pt-3">
            <textarea
              type="text"
              className="form-control Radius_20 "
              style={{ resize: "none" }}
              placeholder="Type Here..."
              name="content"
              value={content}
              rows="4"
              onChange={onHandleChange}
            ></textarea>
          </div>

          <div className="mx-auto">
            <button
              type="button"
              className="btn skyblue White mt-3 d-block mx-auto"
              onClick={validation}
            >
              Post Blog
            </button>
            <p
              className="text-danger text-center mb-0py-2"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              {" "}
              {error}
            </p>
          </div>
          <hr></hr>
          <div>
            <div className=" mx-auto pt-3">
              <h5>Blogs</h5>
              <BlogComponent data={BlogData} FetchBlogs={FetchBlogs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Blogs;
