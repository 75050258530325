import { message, Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import blacklogo from "./assests/blacklogo.png";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const ForgetPassword = () => {
  const [step, setStep] = useState(1); // Tracks the current step in the process
  const [formData, setFormData] = useState({
    email: "",
    code: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false); // Tracks the loading state
  const [showPassword, setShowPassword] = useState(false); // Password visibility toggle state
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Confirm password visibility toggle state
  const { email, code, password, confirmPassword } = formData;

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const requestResetCode = () => {
    if (email !== "") {
      if (email.includes("@") && email.includes(".")) {
        sendEmailRequest();
      } else {
        message.error("Email is not valid", 4);
      }
    } else {
      message.error("Email is Required", 4);
    }
  };

  const sendEmailRequest = () => {
    setLoading(true);
    const link = `${process.env.REACT_APP_API_URL}/api/admin/forget_password`;
    axios
      .post(link, { email })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          message.success(res.data.message);
          setStep(2);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const verifyCode = () => {
    setLoading(true);
    const link = `${process.env.REACT_APP_API_URL}/api/admin/verify_otp`;
    axios
      .post(link, { code, email })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          message.success(res.data.message);
          setStep(3);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const history = useHistory(); // Initialize navigate

  const resetPassword = () => {
    if (password === confirmPassword) {
      if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/.test(
          password
        )
      ) {
        message.error(
          "Password must contain 8+ characters with uppercase, lowercase, number, and symbol."
        );
        return;
      }
      setLoading(true);
      const link = `${process.env.REACT_APP_API_URL}/api/admin/reset_password`;
      axios
        .post(link, { code, password, email })
        .then((res) => {
          setLoading(false);
          if (res.data.success) {
            message.success(res.data.message);
            history.push("/login"); // Use history.push instead of navigate
          } else {
            message.error(res.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      message.error("Passwords do not match", 4);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle confirm password visibility
  };
  return (
    <>
      <div className="col-11 mx-auto pt-3">
        <div className="mx-auto row marginTop">
          <div className="col-md-6 col-11 mx-auto mt-2 bg-white p-5 shadow Radius_4">
            <div className="col-md-6 col-10 mx-auto">
              <img src={blacklogo} alt="" className="mx-auto" />
            </div>

            <form className="pt-4">
              {step === 1 && (
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                    value={email}
                    onChange={onHandleChange}
                    disabled={loading}
                  />
                  <button
                    type="button"
                    className="btn skyblue White col-12 mt-3"
                    onClick={requestResetCode}
                    disabled={loading}
                  >
                    {loading ? <Spin /> : "Request Reset Code"}
                  </button>
                </div>
              )}

              {step === 2 && (
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter verification code"
                    name="code"
                    value={code}
                    onChange={onHandleChange}
                    disabled={loading}
                  />
                  <button
                    type="button"
                    className="btn skyblue White col-12 mt-3"
                    onClick={verifyCode}
                    disabled={loading}
                  >
                    {loading ? <Spin /> : "Verify Code"}
                  </button>
                </div>
              )}

              {step === 3 && (
                <>
                  <div className="form-group" style={{ position: "relative" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Enter new password"
                      name="password"
                      value={password}
                      onChange={onHandleChange}
                      disabled={loading}
                      style={{ paddingRight: "40px" }} // Ensure space for the icon
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)", // Vertically center the icon
                      }}
                    >
                      {showPassword ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </span>
                  </div>
                  <div className="form-group" style={{ position: "relative" }}>
                    <input
                      type={showConfirmPassword ? "text" : "password"} // Toggle between password and text
                      className="form-control"
                      placeholder="Confirm new password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={onHandleChange}
                      disabled={loading}
                      style={{ paddingRight: "40px" }} // Ensure space for the icon
                    />
                    <span
                      onClick={toggleConfirmPasswordVisibility} // Toggle visibility on icon click
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)", // Vertically center the icon
                      }}
                    >
                      {showConfirmPassword ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn skyblue White col-12 mt-3"
                    onClick={resetPassword}
                    disabled={loading}
                  >
                    {loading ? <Spin /> : "Reset Password"}
                  </button>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
