import React, { useEffect, useState } from "react";
import FormsComponent from "./FormsComponent";
import { message } from "antd";
import axios from "axios";

const ContactForms = () => {
  const initialstate = {
    contactData: [],
  };
  const [FormData, setFormData] = useState(initialstate);
  const { contactData } = FormData;

  useEffect(() => {
    ContactData();
    // eslint-disable-next-line
  }, []);

  const ContactData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/contact_us`)
      .then((response) => {
        if (response.data.success) {
          setFormData({
            ...FormData,
            contactData: response.data.data,
          });
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  return (
    <>
      <div className="row mr-0">
        <div className="col-11 mx-auto pt-3">
          <h6>Contact Forms</h6>
          <FormsComponent data={contactData} ContactData={ContactData} />
        </div>
      </div>
    </>
  );
};

export default ContactForms;
