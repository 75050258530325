import React, { useState } from "react";
import { Space, Table, Button, Modal } from "antd";
import { message } from "antd";
import axios from "axios";

export default function BusinessRequestComponent(props) {
  const initialstate = {
    vendorName: "",
    contact: "",
    email: "",
    business_name: "",
    website: "",
    city: "",
    state: "",
    address: "",
    services: [],
    images: [],
    verification_image: [],
    emailText: "",
    _id: "",
  };

  const [FormData, setFormData] = useState(initialstate);
  const {
    vendorName,
    contact,
    email,
    business_name,
    website,
    city,
    state,
    address,
    services,
    images,
    verification_image,
    _id,
    emailText,
  } = FormData;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

  const showModal = (e) => {
    setIsModalVisible(true);
    setFormData({
      ...FormData,
      vendorName: e.first_name + " " + e.last_name,
      contact: e.contact,
      email: e.email,
      business_name: e.business_information.business_name,
      website: e.business_information.website,
      city: e.business_information.city,
      state: e.business_information.State,
      address: e.business_information.address_line_1,
      services: e.services,
      images: e.images,
      verification_image: e.verification_image,
      _id: e._id,
    });
  };

  const showRejectModal = (e) => {
    setIsRejectModalVisible(true);
    setFormData({
      ...FormData,
      _id: e._id,
    });
  };

  const handleOk = (id) => {
    setIsModalVisible(false);
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/admin/approve_vendor/` + id)
      .then((response) => {
        if (response.data.success) {
          message.success("Approved Successfull");
          props.getVenderDetail();
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  const handleCancel = (e) => {
    setIsModalVisible(false);
    setIsRejectModalVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...FormData,
      [name]: value,
    });
  };

  const RenderServices = services.map((item) => {
    return (
      <>
        <tr>
          <td className="text-center">{item.service_name}</td>
          <td className="text-center">{item.service_price}</td>
        </tr>
      </>
    );
  });

  const RenderImages = images.map((source) => {
    return <img src={source} alt="images" className="col-6 p-2"></img>;
  });

  const RenderVerificationImage = verification_image.map((source) => {
    return <img src={source} alt="images" className="col-6 p-2"></img>;
  });

  const Reject = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/admin/vendor_rejection?vendor_id=${id}&emailbody=${emailText}`
      )
      .then((response) => {
        if (response.data.success) {
          message.success("Rejected Successfully");
          setIsRejectModalVisible(false);
          props.getVenderDetail();
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  const columns = [
    {
      title: "ID",
      render: (value) => value._id.slice(-4),
      sorter: {
        compare: (a, b) => a._id.localeCompare(b._id),
      },
    },
    {
      title: "Name",
      render: (value) => value.first_name + " " + value.last_name,
      sorter: {
        compare: (a, b) => a.first_name.localeCompare(b.first_name),
      },
    },
    {
      title: "Number",
      dataIndex: "contact",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Business Name",
      render: (render) => render.business_information.business_name,
      sorter: {
        compare: (a, b) =>
          a.business_information.business_name -
          b.business_information.business_name,
      },
    },
    {
      title: "Business Website",
      render: (render) => render.business_information.website,
    },
    {
      title: "City",
      render: (render) => render.business_information.city,
    },
    {
      title: "State",
      render: (render) => render.business_information.State,
    },
    {
      title: "Address",
      render: (value) => value.business_information.address_line_1,
    },
    {
      title: "Service Name",
      render: (render) => (
        <div class="btn-group">
          <button
            class="btn btn-secondary btn-sm dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Services
          </button>
          <div class="dropdown-menu px-2">
            <span className="d-flex">
              <span className="mr-auto Bold">Name</span>
              <span className="ml-auto Bold">Price</span>
            </span>
            {render.services.map(function (name, index) {
              return (
                <span key={`demo_snap_${index}`} className="d-flex">
                  <span className="mr-auto">{name.service_name}</span>
                  <span className="ml-auto">{name.service_price}</span>
                </span>
              );
            })}
          </div>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => (
        <Space size="middle">
          <Button
            type="primary"
            className="skyblue White border-0"
            onClick={(e) => {
              showModal(record);
            }}
          >
            Details
          </Button>
          <Modal
            okText="Approve"
            title="Business Details"
            visible={isModalVisible}
            onOk={(e) => {
              handleOk(_id);
            }}
            onCancel={(e) => {
              handleCancel(record);
            }}
          >
            <div className="row">
              <div className="col-6">
                <p>
                  <strong>Vendor Name:</strong>
                </p>
                <p>
                  <strong>Contact:</strong>
                </p>
                <p>
                  <strong>Email:</strong>
                </p>
                <p>
                  <strong>Business Name:</strong>
                </p>
                <p>
                  <strong>Website:</strong>
                </p>
                <p>
                  <strong>City:</strong>
                </p>
                <p>
                  <strong>State:</strong>
                </p>
                <p>
                  <strong>Address:</strong>
                </p>
              </div>
              <div className="col-6">
                <p>{vendorName}</p>
                <p>{contact}</p>
                <p>{email}</p>
                <p>{business_name}</p>
                <p>{website}</p>
                <p>{city}</p>
                <p>{state}</p>
                <p>{address}</p>
              </div>
            </div>
            <p>
              <strong>Services:</strong>
            </p>
            {
              <table className="table table-bordered mt-3 ">
                <thead className="skyblue text-center">
                  <tr>
                    <th scope="col" className=" text-center White">
                      Service Name
                    </th>
                    <th scope="col" className="text-center White">
                      Service Price
                    </th>
                  </tr>
                </thead>

                <tbody>{RenderServices}</tbody>
              </table>
            }
            <div className="padding30px">
              <p>
                <strong>Saloon Images:</strong>
              </p>
              <div className="row">{RenderImages}</div>
            </div>

            <div className="padding30px">
              <p className="">
                <strong>Identity Vefication Images:</strong>
              </p>
              <div className="row ">{RenderVerificationImage}</div>
            </div>
          </Modal>
          ,
          <Button
            type="primary"
            className="skyblue White border-0"
            onClick={(e) => {
              showRejectModal(record);
            }}
          >
            Reject
          </Button>
          <Modal
            okText="Send"
            title="Rejection Details"
            visible={isRejectModalVisible}
            onOk={(e) => {
              Reject(_id);
            }}
            onCancel={(e) => {
              handleCancel(record);
            }}
          >
            <textarea
              name="emailText"
              value={emailText}
              className="w-100"
              rows="6"
              placeholder="Enter you Message"
              onChange={handleChange}
              style={{ outline: "none" }}
            />
          </Modal>
        </Space>
      ),
    },
  ];

  const data = props.data;

  function onChange(pagination, filters, sorter, extra) {}
  // eslint-disable-next-line

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChange}
        className="table-responsive"
      />
    </>
  );
}
