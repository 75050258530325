import { React, useState } from "react";
import { Space, Table, message, Modal, Button } from "antd";
import dateFormat from "dateformat";
import UpdateComponent from "./updateImage";
import axios from "axios";

export default function BlogComponent(props) {
  const initialstate = {
    title: "",
    description: "",
    sendimg: [],
    _id: "",
    imgUpdated: false,
    isEditModalVisible: false,
    error: "",
  };

  const [formData, setformData] = useState(initialstate);
  const [imgg, setimgg] = useState([]);

  const {
    description,
    title,
    sendimg,
    _id,
    imgUpdated,
    isEditModalVisible,
    error,
  } = formData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setformData({
      ...formData,
      [name]: value,
      error: "",
    });
  };

  const imagesSet = (imageArray) => {
    setimgg(imageArray);
    setformData({
      ...formData,
      imgUpdated: true,
    });
    // eslint-disable-next-line
    imageArray.map((detail) => {
      setformData({
        ...formData,
        sendimg: detail.originFileObj,
        imgUpdated: true,
        error: "",
      });
    });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (e) => {
    setimgg([
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: e.images[0],
      },
    ]);

    setIsModalVisible(true);

    setformData({
      ...formData,
      title: e.title,
      description: e.description,
      sendimg: imgg,
      _id: e._id,
      imgUpdated: false,
    });
  };

  const handleOk = (e) => {
    if (title !== "") {
      // eslint-disable-next-line
      if (imgUpdated == false || sendimg.length !== 0) {
        if (description !== "") {
          if (description.length > 10) {
            setIsModalVisible(false);
            let forData = new FormData();
            forData.append("title", title);
            forData.append("images", sendimg);
            forData.append("description", description);
            // eslint-disable-next-line
            if (imgUpdated == true) {
              axios
                .put(
                  `${process.env.REACT_APP_API_URL}/api/admin/blog/` + _id,
                  forData
                )
                .then((response) => {
                  if (response.data.success) {
                    props.FetchBlogs();
                  }
                })

                .catch(function (error) {
                  message.error(error);
                });
            }
            // eslint-disable-next-line
            else if (imgUpdated == false) {
              axios
                .put(
                  `${process.env.REACT_APP_API_URL}/api/admin/blog_detail/` +
                    _id,
                  {
                    title: title,
                    description: description,
                  }
                )
                .then((response) => {
                  if (response.data.success) {
                    props.FetchBlogs();
                  }
                })

                .catch(function (error) {
                  message.error(error);
                });
            }
          } else {
            setformData({
              ...formData,
              error: "Description is must be atleast 10 Character long",
            });
          }
        } else {
          setformData({
            ...formData,
            error: "Description is Required",
          });
        }
      } else {
        setformData({
          ...formData,
          error: "Image is required",
        });
      }
    } else {
      setformData({
        ...formData,
        error: "Title is Required",
      });
    }
  };

  const handleCancel = (e) => {
    setimgg([]);

    setIsModalVisible(false);

    setformData({
      ...formData,
      title: "",
      description: "",
      _id: "",
    });
  };

  const showEditModal = () => {
    setformData({
      ...formData,
      isEditModalVisible: true,
    });
  };
  const handleEditOk = () => {
    setformData({
      ...formData,
      isEditModalVisible: false,
    });
  };
  const handleEditCancel = () => {
    setformData({
      ...formData,
      isEditModalVisible: false,
    });
  };

  const columns = [
    {
      title: "ID",
      render: (value) => value._id.slice(-4),
      sorter: {
        compare: (a, b) => a._id.localeCompare(b._id),
      },
    },
    {
      title: "Date Posted",
      render: (value) => dateFormat(value.date, "mm/dd/yyyy"),
      sorter: {
        compare: (a, b) => a.date.localeCompare(b.date),
      },
    },
    {
      title: "Content",
      render: (value) => (
        <p className="mb-0 add-three-dots">{value.description}</p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={(e) => {
              showModal(record);
            }}
          >
            Edit
          </Button>
          <Modal
            title="Edit Blog"
            visible={isModalVisible}
            onOk={(e) => {
              handleOk(_id);
            }}
            onCancel={(e) => {
              handleCancel(record);
            }}
          >
            <h2 className="text-center pt-3">Blogs</h2>
            <div className="col-md-4 col-12">
              <h4>Title</h4>
              <input
                type="text"
                className="form-control Radius_20"
                placeholder="Title Here"
                name="title"
                value={title}
                onChange={onHandleChange}
              ></input>
            </div>
            <h5 className="mt-4">Upload Images</h5>
            <div className="row col-11 my-auto mx-auto">
              <div className="col-md-3 col-sm-6 col-lg-4 col-12 getImg">
                <UpdateComponent
                  imagesSet={imagesSet}
                  limit={1}
                  display={imgg}
                />
              </div>
              <div className="col-md-9 col-sm-6 col-lg-8 col-12">
                <div className="mt-3">
                  <Button
                    type="primary"
                    onClick={showEditModal}
                    className="leftBottom"
                  >
                    Edit Options
                  </Button>
                  <Modal
                    title="Edit Blog"
                    visible={isEditModalVisible}
                    onOk={handleEditOk}
                    onCancel={handleEditCancel}
                  >
                    <div className="row">
                      <p className="col-12">
                        <strong>For Link:</strong> your blog text then &lt;a
                        href="https://your_Link" target="/blank"&gt;Text for
                        Link&lt;/a&gt;then remaining text for blog
                      </p>
                      <p className="col-12">
                        <strong>Link Example:</strong> dummy text &lt;a
                        href="https://www.google.com"
                        target="/blank"&gt;Google&lt;/a&gt;remaining Dummy text
                      </p>

                      <p className="col-12">
                        <strong>For Heading:</strong> your blog text then
                        &lt;h1&gt;Heading text&lt;/h1&gt;then remaining text for
                        blog
                      </p>
                      <p className="col-12">
                        <strong>Heading Example:</strong> Dummy Text
                        &lt;h1&gt;Heading&lt;/h1&gt;Dummy text
                      </p>
                      <p className="col-12">
                        <strong>For Image:</strong> your blog text then &lt;img
                        src="image link" /&gt;then remaining text for blog
                      </p>
                      <p className="col-12">
                        <strong>Image Example:</strong> Dummy Text &lt;img
                        src="image link" /&gt;Dummy text
                      </p>
                      <p className="col-12">
                        <strong>For Next Line</strong> &lt;br/&gt;{" "}
                      </p>
                      <p>
                        You can add other styling by knowning attributes from{" "}
                        <a href="https://www.w3schools.com/" target="/blank">
                          w3school
                        </a>{" "}
                      </p>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="col-12 mx-auto pt-3">
              <textarea
                type="text"
                className="form-control Radius_20 "
                style={{ resize: "none" }}
                placeholder="Type Here..."
                name="description"
                value={description}
                rows="4"
                onChange={onHandleChange}
              ></textarea>
            </div>
            <p
              className="text-danger text-center mb-0py-2"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              {" "}
              {error}
            </p>
          </Modal>
          <button
            type="button"
            className="btn btnClass"
            onClick={(e) => {
              deleteBlog(record._id);
            }}
          >
            Delete
          </button>
        </Space>
      ),
    },
  ];

  const deleteBlog = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/admin/delete_blog/` + id)
      .then((response) => {
        if (response.data.success) {
          message.success("Deleted Successfull");
          props.FetchBlogs();
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };

  const data = props.data;

  function onChange(pagination, filters, sorter, extra) {}
  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      className="table-responsive"
    />
  );
}
