import React from "react";
import BusinessRequestComponent from "./BusinessRequestComponent";
import { message } from "antd";
import axios from "axios";
class BusinessRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BusinessListingData: [],
    };
  }
  componentDidMount = () => {
    this.getVenderDetail();
  };
  getVenderDetail = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/admin/new_vendor_requests`)
      .then((response) => {
        // eslint-disable-next-line
        if (response.data.success == true) {
          this.setState({
            BusinessListingData: response.data.data,
          });
        }
        // eslint-disable-next-line
        else if (response.data.success == false) {
          message.error(response.data.message);
        }
      })

      .catch(function (error) {
        message.error(error);
      });
  };
  render() {
    return (
      <>
        <div className="row h-100 mr-0">
          <div className="col-11 mx-auto pt-3">
            <h6>Business Requests</h6>
            {this.state.BusinessListingData.length > 0 ? (
              <>
                <BusinessRequestComponent
                  data={this.state.BusinessListingData}
                  getVenderDetail={this.getVenderDetail}
                />
              </>
            ) : (
              <div className="container h-100 center Pt25">
                <div className="my-auto px-auto h-100 d-block">
                  <div className="d-flex h-100 justify-content-center">
                    <div className="my-auto">
                      <p>No Requests Available</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default BusinessRequests;
