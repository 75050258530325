import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

const SendPayemnt = () => {
  const { id } = useParams();
  const history = useHistory();
  const [sendPaymentState, setSendPaymentState] = useState({
    paypal_email: "",
    paypal_id: "",
    amount: null,
    payment_type: "",
    booking_id: id,
  });

  const sendPayment = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/admin/send_payment`,
        sendPaymentState
      )
      .then(() => {
        message.success("Payment Sent Successfully!");
        setSendPaymentState(() => {
          return {
            paypal_email: "",
            paypal_id: "",
            amount: null,
            payment_type: "",
            booking_id: "",
          };
        });
        history.push("/orders");
      });
  };
  return (
    <div className="row mr-0">
      <div className="col-11 mx-auto pt-3">
        <h2>Send Payment</h2>
        <div className="row px-0">
          <div className="col-xs-12 col-sm-6">
            <label style={{ fontSize: "12px", marginBottom: "4px" }}>
              Paypal Email
            </label>
            <input
              value={sendPaymentState.paypal_email}
              type="text"
              className="form-control Radius_10 my-1"
              placeholder="Paypal Email"
              name="paypal_email"
              onChange={(e) => {
                setSendPaymentState((prev) => {
                  return {
                    ...prev,
                    paypal_email: e.target.value,
                    paypal_id: "",
                  };
                });
              }}
            ></input>
          </div>
          <div className="col-xs-12 col-sm-6">
            <label style={{ fontSize: "12px", marginBottom: "4px" }}>
              Paypal Id
            </label>
            <input
              value={sendPaymentState.paypal_id}
              type="text"
              className="form-control Radius_10 m-1"
              placeholder="Paypal Id"
              name="paypal_id"
              onChange={(e) => {
                setSendPaymentState((prev) => {
                  return {
                    ...prev,
                    paypal_id: e.target.value,
                    paypal_email: "",
                  };
                });
              }}
            ></input>
          </div>
          <div className="col-xs-12 col-sm-6 mt-2">
            <label style={{ fontSize: "12px", marginBottom: "4px" }}>
              Amount
            </label>
            <input
              value={sendPaymentState.amount}
              type="text"
              className="form-control Radius_10 m-1"
              placeholder="Amount"
              name="amount"
              onChange={(e) => {
                setSendPaymentState((prev) => {
                  return {
                    ...prev,
                    amount: e.target.value,
                  };
                });
              }}
            ></input>
          </div>
          <div className="col-xs-12 col-sm-6 mt-2">
            <label style={{ fontSize: "12px", marginBottom: "4px" }}>
              Payment Type
            </label>
            <select
              value={sendPaymentState.payment_type}
              className="form-control Radius_10 m-1"
              onChange={(e) => {
                setSendPaymentState((prev) => {
                  return {
                    ...prev,
                    payment_type: e.target.value,
                  };
                });
              }}
            >
              <option>Select</option>
              <option value="booking-cancelled">Booking Cancelled</option>
              <option value="booking-completed">Booking Completed</option>
            </select>
          </div>
        </div>
        <div>
          <button
            className="btn White skyblue d-block mx-auto mt-4"
            onClick={sendPayment}
            type="button"
          >
            Send Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendPayemnt;
